import React, { Component } from 'react';

import 'datatables.net-bs4';
import 'datatables.net-buttons-bs4';
import 'datatables.net-select-bs4';
import 'datatables.net-searchpanes-bs4';

import 'datatables.net-responsive-bs4/js/responsive.bootstrap4';
import 'datatables.net-buttons/js/buttons.print';
import 'datatables.net-buttons/js/buttons.html5';
import 'datatables.net-buttons/js/buttons.colVis';

import DataTables, { Config } from 'datatables.net';
import AutoNumeric from 'autonumeric';
import JSZip from 'jszip';

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { ConfigColumnDefs, ConfigColumns } from 'datatables.net';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

declare var window: any;
window.JSZip = JSZip;

const $: JQueryStatic & { DataTable: DataTables<any>} = require('jquery');
$.DataTable = DataTables;

window.jQuery = $;

type Props = {
  id: string;
  columns: ConfigColumns[];
  columnDefs: ConfigColumnDefs[] | null;
  datas: Array<object | null>;
  buttons: any;
  lengthChange?: boolean;
  lengthMenu?: Array<number>;
  order?: (string | number)[][];
  didMount?: any;
  dom?: string;
  searchPanes?: any;
  searching?: boolean;
  paging?: boolean;
  info?: boolean;
};

type State = {
  data: Array<any>;
}

class Table extends Component<Props, State> {
  // State array variable to save and show data
  refs: any;

  constructor(props: Props) {
    super(props)
    this.state = {
      data: props.datas
    };

  }

  initializeSettings() {
    $('div#' + this.props.id + '_length').on('change', 'select[name=' + this.props.id + '_length]', (e: any) => {
      let config: { [key: string]: string } = JSON.parse(localStorage.getItem('config') ?? '{}');
      config[this.props.id + '_length'] = e.target.value;
      localStorage.setItem('config', JSON.stringify(config));
    });

    let table = $(this.refs[this.props.id]).DataTable();

    $('#' + this.props.id).on('order.dt', () => {
      let config: { [key: string]: any } = JSON.parse(localStorage.getItem('config') ?? '{}');
      let order = table.order();

      if (order && order.length > 0) {
        config[this.props.id + '_order'] = order;
      }

      localStorage.setItem('config', JSON.stringify(config));
    });
  }

  componentDidMount() {
    this.initializeTable();

    $('a[data-toggle="tab"]').on('shown.bs.tab', () => {
      $.fn.dataTable.tables({ visible: true, api: true })//.columns.adjust();
    });

    this.initializeSettings();
  }

  initializeTable() {
    const customDom = "<'d-flex justify-content-between align-items-center my-3'Bf>t<'d-flex justify-content-between align-items-center m-2'lip>"
    const { columns, columnDefs, buttons, lengthChange = true, lengthMenu = [10, 50, 100, 200, 500], order, datas, didMount, dom = customDom, searchPanes, searching, paging, info } = this.props;

    if ($.fn.dataTable.isDataTable(this.refs[this.props.id])) {
      $(this.refs[this.props.id]).DataTable().destroy();
    }

    const config: { [key: string]: any } = JSON.parse(localStorage.getItem('config') ?? '{}');
    const pageLength = config[this.props.id + '_length'];
    const tableOrder = config[this.props.id + '_order'];

    const dtconfig: Config & { responsive?: boolean } = {
      autoWidth: true,
      responsive: true,
      lengthChange,
      lengthMenu,
      pageLength: pageLength ? Number(pageLength) : lengthMenu[0],
      order: tableOrder ? tableOrder : (order ? order : [[0, 'asc']]),
      dom: dom,
      data: datas,
      columns,
      columnDefs: columnDefs ?? [],
      buttons: buttons,
      scrollCollapse: true,
      searchPanes,
      searching,
      paging,
      info
    }

    $(this.refs[this.props.id]).DataTable(dtconfig).buttons().container().appendTo('#' + this.props.id + '_wrapper .col-md-6:eq(0)');

    const autoNumericOptionsEuro: any = {
      digitGroupSeparator: '.',
      decimalCharacter: ',',
      decimalCharacterAlternative: '.',
      currencySymbol: '€',
    };

    if (document.getElementById('#' + this.props.id)) {
      AutoNumeric.multiple('#' + this.props.id + ' .autoNumeric', autoNumericOptionsEuro)
    }

    didMount && didMount();
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevProps.datas !== this.props.datas) {
      this.initializeTable();
      this.initializeSettings();
    }
  }

  componentWillUnmount() {
    $('#' + this.props.id + ' .data-table-wrapper')
      .find('table')
      .DataTable()
      .destroy(true);
  }

  /*shouldComponentUpdate() {
    return false;
  }*/

  render() {
    const { data } = this.state;

    return data && (
      <div>
        <table ref={this.props.id} id={this.props.id} style={{ 'width': '100%' }} className="table table-bordered table-striped table-light table-responsive" />
      </div>
    )
  }
}
export default Table;