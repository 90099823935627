import { CausalDipendente, IPermessiFeriePerMese, PermessiferieDipendente } from '../../helpers/interfaces/permessiferie';
import axios from '../api';
import { AxiosResponse } from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

class PermessiFerieService {
  async getPermessiFerie(limit: any, offset: any, stato_id?: Number) {
    let url = 'datore/permessi-ferie';
    if (stato_id) { url += '/' + stato_id; }

    return await axios.get<any>(API_URL + url + '?limit=' + limit + '&offset=' + offset)
      .then(response => { return response.data; })
      .catch((error) => { return Promise.reject(); });
  }

  async getAllForMonth(year: number, month: number) {
    return await axios.get<IPermessiFeriePerMese[]>(API_URL + 'datore/permessi-ferie/annomese/' + year + '/' + month)
      .then(response => { return Array.isArray(response.data) ? response.data : []; })
      .catch((error) => { return Promise.reject(); });
  }

  async getAllForDipendente(id: number) {
    return await axios.get<IPermessiFeriePerMese[]>(API_URL + 'datore/permessi-ferie/dipendente/' + id)
      .then(response => { return Array.isArray(response.data) ? response.data : []; })
      .catch((error) => { return Promise.reject(); });
  }

  async confirm(id: Number) {
    return await axios.get(API_URL + 'datore/permessi-ferie/confirm/' + id)
      .then(response => { return response.data; })
      .catch((error) => { return Promise.reject(); });
  }

  async refuse(id: Number, data: any) {
    return await axios.delete(API_URL + 'datore/permessi-ferie/' + id, {
      data
    })
      .then(response => { return response.data; })
      .catch((error) => { return Promise.reject(); });
  }

  async getCausalDipendente(): Promise<Array<CausalDipendente>> {
    return await axios.get(API_URL + 'dipendente/causali')
      .then((response: AxiosResponse<Array<CausalDipendente>>) => { return Array.isArray(response.data) ? response.data : []; })
      .catch((error) => { return Promise.reject(error); });
  }

  async getPermessiFerieDipendente(stato_id?: Number): Promise<Array<PermessiferieDipendente>> {
    let url = 'dipendente/permessi-ferie';
    if (stato_id) { url += '/' + stato_id; }

    return await axios.get(API_URL + url)
      .then((response: AxiosResponse<Array<PermessiferieDipendente>>) => { return response.data; })
      .catch((error) => { return Promise.reject(error); });
  }

  async createPermessiFerie(inizio: string, fine: string, causaliId: string, person: number): Promise<any> {
    const causali_id = parseInt(causaliId);

    return await axios.post(API_URL + 'datore/permessi-ferie/add', {
      inizio,
      fine,
      causali_id,
      person
    })
      .then((response: AxiosResponse<any>) => { return response.data; })
      .catch((error) => { return Promise.reject(error); });
  }

  async createPermessiFerieDipendente(inizio: string, fine: string, causaliId: string): Promise<any> {
    const causali_id = parseInt(causaliId);

    return await axios.post(API_URL + 'dipendente/permessi-ferie', {
      inizio,
      fine,
      causali_id
    })
      .then((response: AxiosResponse<any>) => { return response.data; })
      .catch((error) => { return Promise.reject(error); });
  }

  async deletePermessiFerieDipendente(id: number): Promise<null> {
    let url = 'dipendente/permessi-ferie/' + id;

    return await axios.delete(API_URL + url)
      .then((response: AxiosResponse<null>) => { return response.data; })
      .catch((error) => { return Promise.reject(error); });
  }
}
export default new PermessiFerieService();