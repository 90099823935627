import React, { Component, MouseEvent } from 'react'
import EventBus from '../../../common/EventBus';
import Swal from "sweetalert2";
import Table from '../../../common/TableComponent';
import getVeicoliColumnDefs, { getVeicoloDettaglioManutenzioneColumnDefs, listVeicoliColumns, listVeicoloDettaglioManutenzioneColumns } from '../../../config/tableColumns/magazzino/magazzino-veicoli';
import { IMagazzinoVeicolo } from '../../../helpers/interfaces/magazzino/magazzino-veicoli';
import magazzinoVeicoliService from '../../../services/api/magazzino/magazzino-veicoli.service';
import ModalForm from '../../../common/ModalForm';
import { iFormField, iFormFieldOption } from '../../../helpers/interfaces/generic';
import { veicoliFormFields } from '../../../config/formFields/magazzino/magazzino-veicoli';
import Modal from '../../../common/Modal';
import $ from 'jquery';

type State = {
    veicoli: IMagazzinoVeicolo[],
    veicoliOptions: iFormFieldOption[],
    showModal: boolean,
    showModalForm: boolean,
    modalTitle: string,
    veicoloDettaglio: IMagazzinoVeicolo | null,
    modalFormType: string,
    modalFormTitle: string,
    formFields: iFormField[],
    formInitialValues: IMagazzinoVeicolo| {},
    loading: boolean,
}

export default class MagazzinoVeicoli extends Component<{}, State>
{
    apiSubmit: any;

    constructor(props: {}) {
        super(props)

        this.state = {
            veicoli: [],
            veicoliOptions: [],
            showModal: false,
            showModalForm: false,
            modalTitle: 'Manutenzioni veicolo',
            veicoloDettaglio: null,
            modalFormType: '',
            modalFormTitle: '',
            formFields: [],
            formInitialValues: {},
            loading: false,
        }

        this.apiSubmit = null;
    }

    async componentDidMount() {
        EventBus.dispatch("showLoader", { text: 'Caricamento delle manutenzioni veicoli in corso...' });

        const veicoli= await magazzinoVeicoliService.getAll().then(
            async (veicoli: IMagazzinoVeicolo[]) => { return veicoli; }
        ).catch(
            (error: any) => { return []; }
        );

        this.setState({
            veicoli,
        });

        EventBus.dispatch("hideLoader");

        $('#table_magazzino_veicoli.table').on('click', '.maintenances_btn', async (e: JQuery.ClickEvent) => {
            e.preventDefault();

            const idVeicolo = $(e.currentTarget).data('id');

            await magazzinoVeicoliService.getOne(idVeicolo).then(
                async (veicoloDettaglio: IMagazzinoVeicolo) => {
                    this.setState({
                        showModal: true,
                        modalTitle: `Manutenzione veicolo ${veicoloDettaglio.plate} (${veicoloDettaglio.brand} ${veicoloDettaglio.model})`,
                        veicoloDettaglio
                    })
                },
                (error: any) => { return null }
            );
        });

        $('#table_magazzino_veicoli.table').on('click', '.edit_btn', async (e: JQuery.ClickEvent) => {
            e.preventDefault();

            const idVeicolo = $(e.currentTarget).data('id');

            await magazzinoVeicoliService.getOne(idVeicolo).then(
                async (veicoli: IMagazzinoVeicolo) => {
                    await this.loadHandler(
                        veicoli,
                        magazzinoVeicoliService.edit,
                        'edit',
                        "Modifica veicolo"
                    );
                },
                (error: any) => { return null }
            );
        });

        $('#table_magazzino_veicoli.table').on('click', '.delete_btn', async (e: JQuery.ClickEvent) => {
            e.preventDefault();

            Swal.fire({
                title: 'Vuoi eliminare il veicolo sbagliato?',
                text: "ATTENZIONE: la cancellazione non è reversibile!! Eliminare solo veicoli creati per sbaglio e non utilizzati!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Conferma',
                cancelButtonText: 'Annulla'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const idVeicolo = $(e.currentTarget).data('id');
                    const response: any = await magazzinoVeicoliService.delete(idVeicolo);
                    if (response && typeof response.error !== 'undefined') {
                        Swal.fire(
                            response.error,
                            '',
                            'error'
                        );
                    } else {
                        window.location.reload();
                    }
                }
            });
        });
    }

    async addHandler() {
        await this.loadHandler(
            {},
            magazzinoVeicoliService.add,
            'add',
            "Crea nuova veicolo"
        );
    }

    async loadHandler(
        formInitialValues: IMagazzinoVeicolo| {},
        apiSubmit: any,
        modalFormType: 'add' | 'edit',
        modalFormTitle: string
    ) {
        EventBus.dispatch("showLoader", { text: 'Caricamento dei dati in corso...' });

        this.apiSubmit = apiSubmit;

        this.setState({
            formFields: veicoliFormFields(),
            formInitialValues,
            modalFormType,
            modalFormTitle
        }, async() => {
            this.setState({showModalForm: true});
        });

        EventBus.dispatch("hideLoader");
    }

    closeModal() {
      this.setState({ showModal: false });
    }

    closeModalForm() {
      this.setState({ showModalForm: false });
    }
  
    handleSuccess() {
      window.location.hash = "#veicoli";
      window.location.reload();
    }

    render() {
        const { veicoli, showModal, showModalForm, modalTitle, veicoloDettaglio, modalFormType, modalFormTitle, formFields, formInitialValues } = this.state;

        const buttons = ['excel', 'pdf', 'print']
        return (
            <React.Fragment>
                <Modal
                    showModal={showModal}
                    title={modalTitle}
                    type={'table'}
                    closeCallback={this.closeModal.bind(this)}
                    content={
                        <Table
                            id="table_veicolo_dettagli"
                            columns={listVeicoloDettaglioManutenzioneColumns}
                            columnDefs={getVeicoloDettaglioManutenzioneColumnDefs()}
                            datas={veicoloDettaglio?.maintenances ?? []}
                            buttons={null}
                    />}
                />
                <ModalForm
                    showModal={showModalForm}
                    title={modalFormTitle}
                    modalType={modalFormType}
                    formFields={formFields}
                    initialValues={formInitialValues}
                    apiSubmit={this.apiSubmit}
                    closeCallback={this.closeModalForm.bind(this)}
                    successAction={this.handleSuccess.bind(this)}
                />
                <div className='custom-container'>
                    <div className="card">
                        <div className="card-body">
                            <div className="form-group mb-3 d-flex justify-content-between align-items-center">
                                <h2 className="card-title">veicoli</h2>
                                <a
                                    id="add_btn"
                                    href="#add"
                                    className="btn btn-outline-primary"
                                    onClick={(e: MouseEvent) => {
                                        e.preventDefault();
                                        this.addHandler();
                                    }}
                                >
                                    <span>Aggiungi veicolo</span>
                                </a>
                            </div>
                            {
                                veicoli.length > 0 &&
                                <Table
                                    id={'table_magazzino_veicoli'}
                                    columns={listVeicoliColumns}
                                    columnDefs={getVeicoliColumnDefs()}
                                    datas={veicoli}
                                    buttons={buttons}
                                />
                            }
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}