import React from 'react';
import { Component } from 'react';
import eventBus from '../../common/EventBus';
import Table from '../../common/TableComponent';
import getNoteSpeseColumnDefs, { listNoteSpeseColumns } from '../../config/tableColumns/note-spese-dipendente';
import { addNotaSpesaFormFields } from '../../config/formFields/note-spese-dipendente';
import { ITypeNote, INoteSpese } from '../../helpers/interfaces/note-spese';
import { Profilo } from "../../helpers/interfaces/user";
import NoteSpeseService from '../../services/api/note-spese.service';
import UserService from "../../services/api/user.service";
import NoteSpeseSelect from './note-spese-select';
import { Field, FormikProps } from "formik";
import { iFormField, iFormFieldValue } from "../../helpers/interfaces/generic";
import ModalForm from '../../common/ModalForm';
import * as Yup from "yup";
import "moment/locale/it";
import Swal from "sweetalert2";
import { base64toBlob } from '../../common/Base64';

const $ = require('jquery');

type Props = {
    renderField: (item: iFormField, key: number, formik: FormikProps<any>) => false | JSX.Element
}

type State = {
    loading: boolean,
    formFields: Array<iFormField>,
    formInitialValues: { [key: string]: any },
    expenses: INoteSpese[],
    showModal: boolean,
    modalType: string,
    modalTitle: string
}

export default class NoteSpese extends Component<Props, State> {
    
    apiSubmit: any;
    tipologieNota: iFormFieldValue[] = [];
    commesse: iFormFieldValue[] = [];

    constructor(props: Props) {
        super(props);    

        this.state = {
            loading: false,
            formFields: [],
            formInitialValues: {},
            expenses: [],
            showModal: false,
            modalType: 'add',
            modalTitle: ''
        }

        this.getExpensesFromChild = this.getExpensesFromChild.bind(this);
        this.showNoteTable = this.showNoteTable.bind(this);
    }

    async componentDidMount(): Promise<void> {
        eventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });

        await NoteSpeseService.getType()
        .then((_tipologieNota: ITypeNote[]) => {
            if (_tipologieNota && _tipologieNota.length > 0)
            {
                _tipologieNota.sort((a: any, b: any) => {
                    return a.nome.localeCompare(b.nome);
                });
                this.tipologieNota = _tipologieNota.map((item: ITypeNote) => { return { key: item.id, value: item.nome } });
            }
        });
        
        await UserService.getProfile()
        .then((profile: Profilo) => {
            if (profile && profile.personjoborders && profile.personjoborders.active && profile.personjoborders.active.length > 0)
                this.commesse = profile.personjoborders.active.map((item: any) => { return { key: item.joborderid, value: item.code + ' - '  + item.name } });
        });

        eventBus.dispatch("hideLoader");
    }

    getExpensesFromChild(data: INoteSpese[]) {
        this.setState({ expenses: data });
        this.showNoteTable();
    }

    showNoteTable() {
        // $('#table_note-spese').on('click', '.edit_btn', async (e: any) => {
        //     e.preventDefault();
        //     eventBus.dispatch("showLoader", { text: 'Caricamento nota spese in corso...' });

        //     const idNota = $(e.currentTarget).data('id');
        //     await NoteSpeseService.getOne(idNota)
        //     .then((response: INoteSpese) => {
        //         eventBus.dispatch("hideLoader");

        //         if (!response || (response.status && response.status.toLowerCase() === 'error')) {
        //             Swal.fire({
        //                 title: 'Errore',
        //                 text: 'Si è verificato un errore nel caricamento dei dati.',
        //                 icon: 'error',
        //                 })
        //         } else {
        //             const notaSpese = {
        //                 amount: response.amount,
        //                 // attachments: [
        //                 //     {
        //                 //         id: response.attachments[0]?.id,
        //                 //         nome: response.attachments[0]?.nome,
        //                 //         path: response.attachments[0]?.path,
        //                 //         note: response.attachments[0]?.note
        //                 //     }
        //                 // ],
        //                 credit: response.creditcard,
        //                 date: response.date,
        //                 id: response.id,
        //                 joborder: {
        //                     id: response.joborder?.id,
        //                     name: response.joborder?.name,
        //                     code: response.joborder?.code,
        //                     start: response.joborder?.start,
        //                     expire: response.joborder?.expire,
        //                     customer: response.joborder?.customer
        //                 },
        //                 km: response.km,
        //                 tipologia: {
        //                     id: response.tipologia?.id,
        //                     nome: response.tipologia?.nome
        //                 },
        //                 tragitto: response.tragitto,
        //                 // user: {
        //                 //     id: response.user?.id,
        //                 //     fullname: response.user?.fullname
        //                 // }
        //             }

        //             // const attachmentsList: string[] = [];
        //             // notaSpese.attachments.map(item => attachmentsList.push(item.path));

        //             this.setState({
        //                 formFields: editNotaSpesaFormFields(this.tipologieNota, this.commesse),
        //                 formInitialValues: { ...notaSpese, 
        //                                     tipologia: notaSpese.tipologia?.id ?? '',
        //                                     credit: notaSpese.credit ? 1 : 0,
        //                                     joborder: notaSpese.joborder?.id ?? '',
        //                                     // user: notaSpese.user?.id,
        //                                     // attachments: {...response.attachments}
        //                                 }
        //             }, () => {
        //                 this.apiSubmit = NoteSpeseService.edit;
        //                 this.setState({ showModal: true, modalTitle: 'Modifica nota spese', modalType: 'edit' });
        //             });
        //         }
        //     })
        //     .catch((error: string) => {
        //         eventBus.dispatch("hideLoader");

        //         Swal.fire({
        //             title: 'Errore',
        //             text: error,
        //             icon: 'error',
        //             });
        //     })
        // });

        $('#table_note-spese.table').on('click', '.delete_btn', async (e: any) => {
            e.preventDefault();
            Swal.fire({
                title: 'Vuoi confermare la cancellazione?',
                //text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Conferma',
                cancelButtonText: 'Annulla'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const idNota = $(e.currentTarget).data('id');
                    const notaSpese: any = await NoteSpeseService.delete(idNota);
                    if (notaSpese && typeof notaSpese.error !== 'undefined') {
                        Swal.fire(
                            notaSpese.error,
                            '',
                            'error'
                        );
                    } else {
                        window.location.reload();
                    }
                }
            });
        });

        $('#table_note-spese.table').on('click', '.download_link', async (event: any) => {
            event.preventDefault();
            
            eventBus.dispatch("showLoader", { text: 'Download in corso...' });

            const id = event.currentTarget.dataset.id;
    
            this.setState({
                loading: true
            })
    
            await NoteSpeseService.downloadAttachment(id).then(
                documento => {
                    eventBus.dispatch("hideLoader");
                    this.setState({
                        loading: false
                    })
    
                    let fileBlob = base64toBlob(documento.body, documento.mimetype)
                    let url = window.URL.createObjectURL(fileBlob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = documento.filename;
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                },
                () => {
                    eventBus.dispatch("hideLoader");
    
                    Swal.fire(
                        'Errore',
                        '',
                        'error'
                    );
    
                    this.setState({
                        loading: false
                    });
                }
            )
        });
    }

    async openAddModale() {
        this.apiSubmit = NoteSpeseService.add;

        const notaSpese = {
            amount: '',
            attachments: undefined,
            credit: '0',
            date: '',
            id: '',
            joborder: undefined,
            km: '',
            tipologia: undefined,
            tragitto: '',
            // user: undefined
        }

        this.setState({
            formFields: addNotaSpesaFormFields(this.tipologieNota, this.commesse),
            formInitialValues: notaSpese,
            showModal: true,
            modalType: 'add',
            modalTitle: 'Aggiungi una nuova nota spese'
        });
    }

    closeModal() {
        this.setState({ showModal: false });
    }

    validationSchema() {
        let validations: any = {};
        this.state.formFields.forEach((value: any, key: any) => (validations[value.name] = value.validation));

        return Yup.object().shape(validations);
    }

    renderField(item: iFormField, key: number, formik: FormikProps<any>) {
        switch (item.type) {
            case 'select':
                return <React.Fragment>
                    <label className="form-label col-3 col-form-label">{item.label}</label>
                    <div className="col">
                        <Field as={item.type} name={item.name} className={item.class} onChange={async (event: any) => {

                            formik.setFieldValue(item.name, event.target.value);
                            if (typeof item.updateField !== 'undefined' && typeof item.updateCallback !== 'undefined') {
                                await item.updateCallback(event.target.value, formik);
                            }
                        }}>
                            <option key={''} value={''} disabled>{item.value ?? 'Tutti'}</option>
                            {item.values && item.values.map((option: iFormFieldValue) => {
                                    return <option key={option.key} value={option.key}>{option.value}</option>
                            })}
                        </Field>
                    </div>
                </React.Fragment>
            default:
                return <React.Fragment>
                    <label className="form-label col-3 col-form-label">{item.label}</label>
                    <div className="col">
                        <Field name={item.name} type={item.type} className={item.class} />
                    </div>
                </React.Fragment>
        }
    }

    render() {
        const { formFields, formInitialValues, showModal, modalTitle, modalType } = this.state;
        let initialValues: any = {};
        initialValues['year'] = '';
        initialValues['month'] = '';

        const buttons = ['excel', 'pdf', 'print']
 
        return (
            <React.Fragment>
                <div className='custom-container'>
                    <div className="card">
                        <div className="card-body">
                            <div className="form-group mb-3 d-flex justify-content-between align-items-center">
                                <h2 className="card-title">Note spese</h2>
                               
                                <button id="add_btn" type="button" className="btn btn-outline-primary" onClick={async () => this.openAddModale()}>
                                    <span>Aggiungi nota spesa</span>
                                </button>
                            </div>
                            
                            <NoteSpeseSelect renderField={this.renderField.bind(this)} parentCallback={this.getExpensesFromChild} />
                            <div className="mt-5"></div>
                            {
                                this.state.expenses.length > 0 &&
                                <Table id={'table_note-spese'} columns={listNoteSpeseColumns} columnDefs={getNoteSpeseColumnDefs()} datas={this.state.expenses} buttons={buttons} />
                            }
                            
                            <ModalForm
                                showModal={showModal}
                                title={modalTitle}
                                modalType={modalType}
                                formFields={formFields}
                                initialValues={formInitialValues}
                                apiSubmit={this.apiSubmit}
                                closeCallback={this.closeModal.bind(this)}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}