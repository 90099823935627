import React, { Component } from 'react'
import Swal from "sweetalert2";
import EventBus from '../../../common/EventBus';
import { TabProp, Tab } from '../../../common/TabComponent';
import getStrumentiColumnDefs, { listAssegnatariColumns, listStrumentiColumns } from '../../../config/tableColumns/magazzino/magazzino-strumenti';
import { IAssegnatario, IMagazzinoStrumento } from '../../../helpers/interfaces/magazzino/magazzino-strumenti';
import tipologiaStrumentiService from '../../../services/api/magazzino/magazzino-tipologia-strumenti.service';
import magazzinoStrumentiService from '../../../services/api/magazzino/magazzino-strumenti.service';
import Table from '../../../common/TableComponent';
import { IMagazzinoTipologiaStrumento } from '../../../helpers/interfaces/magazzino/magazzino-tipologia-strumenti';
import Modal from '../../../common/Modal';

const $ = require('jquery')

type State = {
    tipologie: IMagazzinoTipologiaStrumento[],
    strumenti: IMagazzinoStrumento[],
    strumentiTabs: TabProp[],
    storicoModal: boolean,
    assegnatari: IAssegnatario[]
}

export default class MagazzinoStrumenti extends Component<{}, State> {

    constructor(props: {}) {
        super(props)

        this.state = {
            tipologie: [],
            strumenti: [],
            strumentiTabs: [],
            storicoModal: false,
            assegnatari: []
        }
    }

    async componentDidMount() {

        EventBus.dispatch("showLoader", { text: 'Caricamento degli strumenti in corso...' });

        const tipologiePromise = tipologiaStrumentiService.getAll();
        const strumentiPromise = magazzinoStrumentiService.getAll();

        // loading the devices (for tables) and the types (for tabs)
        await Promise.all([strumentiPromise, tipologiePromise])
            .then(
                values => this.setState(
                    {
                        strumenti: values[0],
                        tipologie: values[1]
                    },
                    () => {
                        let strumentiTabs: TabProp[] = this.state.tipologie.map(tipologia => {
                            let strumentiInTable = []

                            let searchPanes = {
                                viewTotal: true,
                                initCollapsed: true,
                                columns: [1, 4, 6, 8, 12],
                                layout: "columns-2"
                            }

                            strumentiInTable = [...values[0]].filter(strumento => strumento.tipologia_nome === tipologia.nome)

                            const dom = "P<'d-flex justify-content-between align-items-center my-3'Bf>t<'d-flex justify-content-between align-items-center my-3'l<'p-0'i>p>"

                            return {
                                id: 'tab_magazzino_strumenti_' + tipologia?.nome.toLowerCase().replace(/\s/g, "_"),
                                label: tipologia ? tipologia.nome : '',
                                content: (<Table id={'table_magazzino_strumenti_' + tipologia?.nome.toLowerCase().replace(/\s/g, "_")} columns={listStrumentiColumns} columnDefs={getStrumentiColumnDefs()} datas={strumentiInTable} dom={dom} searchPanes={searchPanes} buttons={['excel']} />),
                                isActive: false
                            }
                        })

                        if (strumentiTabs.length !== 0)
                            strumentiTabs[0].isActive = true

                        this.setState({ strumentiTabs }, () => EventBus.dispatch("hideLoader"))
                    }
                ),
                error => EventBus.dispatch("hideLoader")
            );

        $('.table').on('click', '.storico_btn', async (e: any) => {
            e.preventDefault();
            EventBus.dispatch("showLoader", { text: 'Caricamento storico in corso...' });

            const strumentoId = $(e.currentTarget).data('id');
            magazzinoStrumentiService.getOne(strumentoId)
                .then(
                    strumento => this.setState(
                        {
                            assegnatari: [], // TODO strumento.storico_assegnatari,
                            storicoModal: true
                        },
                        () => EventBus.dispatch("hideLoader")
                    ),
                    () => EventBus.dispatch("hideLoader")
                )

        });

        $('.table').on('click', '.delete_btn', async (e: any) => {
            e.preventDefault();
            Swal.fire({
                title: 'Vuoi eliminare lo strumento sbagliato?',
                text: "ATTENZIONE: la cancellazione non è reversibile!! Eliminare solo strumenti creati per sbaglio e non utilizzati!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Conferma',
                cancelButtonText: 'Annulla'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const idStrumento = $(e.currentTarget).data('id');
                    const response: any = await magazzinoStrumentiService.delete(idStrumento);
                    if (response && typeof response.error !== 'undefined') {
                        Swal.fire(
                            response.error,
                            '',
                            'error'
                        );
                    } else {
                        window.location.reload();
                    }
                }
            });
        });
    }

    closeModal() {
        this.setState({ storicoModal: false })
    }

    render() {
        const { strumenti, strumentiTabs, storicoModal, assegnatari } = this.state;

        return (
            <React.Fragment>
                <div className='custom-container'>
                    <div className="card">
                        <div className="card-body">
                            <div className="mb-3 d-flex justify-content-between align-items-center">
                                <h2 className="card-title">Strumenti</h2>
                                <a href="/magazzino/strumenti/nuovo" id="add_btn" className="btn btn-outline-primary">
                                    <span>Aggiungi strumento</span>
                                </a>
                            </div>
                            <Modal id="assegnatari" showModal={storicoModal} title={'Elenco assegnatari'} closeCallback={this.closeModal.bind(this)} type={''} closeButton content={
                                <Table id="table_storico_assegnatari" columns={listAssegnatariColumns} columnDefs={null} datas={assegnatari} buttons={null} lengthChange={false} lengthMenu={[10]} />}
                            />
                            {
                                strumenti.length > 0 &&
                                <React.Fragment>
                                    <Tab tabs={strumentiTabs} />
                                </React.Fragment>
                            }
                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}