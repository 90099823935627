import { generatePath } from "react-router-dom";
import { ConfigColumnDefs, ConfigColumns } from 'datatables.net';
import { IMagazzinoStrumento } from "../../../helpers/interfaces/magazzino/magazzino-strumenti";

// table columns definitions
export const listStrumentiColumns: ConfigColumns[] = [
    { title: "Azioni", data: "id" },
    { title: "Stato", data: "stato_nome" },
    { title: "Categoria", data: "categoria_nome" },
    { title: "Code", data: "code" },
    { title: "Marca modello", data: "marca_modello" },
    { title: "Serial number", data: "serial_number" },
    { title: "Builder", data: "builder" },
    { title: "Misurament range", data: "misuramentrange" },
    { title: "Calibration date", data: "calibrationdate" },
    { title: "Frequency", data: "frequency" },
    { title: "Expiration", data: "expiration" },
    { title: "Location", data: "location" },
    { title: "Purchase", data: "purchase" },
    { title: "Warranty", data: "warranty" },
    { title: "Verify", data: "verify" },
    { title: "Acceptability", data: "acceptability" },
    { title: "Disponibile", data: "disponibile", searchable: false },
    { title: "Allegati", data: "attachments", searchable: false, defaultContent: "-" }
];

export const listAssegnatariColumns: ConfigColumns[] = [  // TODO - SERVE???
    { title: "Assegnatario", data: "assegnatario" },
    { title: "Consegna", data: "data_consegna" },
    { title: "Restituzione", data: "data_restituzione" },
]

// function: setting columns defs (in this case, setting the edit button at the first column)
export default function getStrumentiColumnDefs(): ConfigColumnDefs[] {
    return [
        {
            target: 0,
            render: (id: number, type: string, row: IMagazzinoStrumento) => {
                const url = generatePath("/magazzino/strumenti/dettaglio-strumento/:id", { id })

                const edit = `<a class="custom-icon btn btn-outline-primary rounded-circle me-1" href="${url}" data-id="'+${id}+'"> 
                    <i style="font-size: 18px" class="fa fa-pencil" aria-hidden="true"></i>
                </a>`;

                const trash = `<a class="delete_btn custom-icon btn btn-outline-danger rounded-circle me-1" data-id="${id}"><i style="font-size: 18px" class="fa fa-trash" aria-hidden="true"></i></a>`;

                // const showStorico = `<a class="storico_btn custom-icon btn btn-outline-secondary rounded-circle" data-id="${id}"> 
                //     <i style="font-size: 18px" class="fa fa-history" aria-hidden="true" title="Storico assegnatari"></i>
                // </a>`

                // return '<div class="d-flex">' + edit + (row.storico_assegnatari.length > 0 ? showStorico : '') + '</div>';
                return '<div class="d-flex">' + trash + edit + '</div>';
            },
        },
        {
            target: listStrumentiColumns.length - 1,
            render: (id: number, type: string, row: IMagazzinoStrumento) => {
                let files = row.attachments?.map((a: any) => a.path);
                return files && files.length > 0 ? `<p data-id="'+${id}+'">${files.toString()}</p>` : '';;
            },
        },
    ];
}