import { ConfigColumnDefs, ConfigColumns } from "datatables.net";
import { IMagazzinoVeicolo, IMagazzinoVeicoloManutenzione } from "../../../helpers/interfaces/magazzino/magazzino-veicoli";

// table columns definitions
export const listVeicoliColumns: ConfigColumns[] = [
    { title: "Azioni", data: "id" },
    { title: "Marca", data: "brand" },
    { title: "Modello", data: "model" },
    { title: "Targa", data: "plate" },
];

// function: setting columns defs (in this case, setting the edit button at the first column)
export default function getVeicoliColumnDefs(): ConfigColumnDefs[] {
    return [
        {
            target: 0,
            render: (id: number, type: string, row: IMagazzinoVeicolo) => {
                const maintenances = `<a class="maintenances_btn custom-icon btn btn-outline-primary rounded-circle" data-id="${id}">
                    <i style="font-size: 18px" class="fa fa-wrench" aria-hidden="true"></i>
                </a>`;

                const edit = `<a class="edit_btn custom-icon btn btn-outline-primary rounded-circle" data-id="${id}">
                    <i style="font-size: 18px" class="fa fa-pencil" aria-hidden="true"></i>
                </a>`;

                const trash = `<a class="delete_btn custom-icon btn btn-outline-danger rounded-circle me-1" data-id="${id}"><i style="font-size: 18px" class="fa fa-trash" aria-hidden="true"></i></a>`;

                return `<div class="d-flex">${trash}${'&nbsp;'}${maintenances}${'&nbsp;'}${edit}</div>`;
            },
        },
    ];
}

// table columns definitions
export const listVeicoloDettaglioManutenzioneColumns: ConfigColumns[] = [
    { title: "Azioni", data: "id" },
    { title: "Tipologia", data: "type" },
    { title: "Data", data: "date" },
];

// function: setting columns defs (in this case, setting the edit button at the first column)
export function getVeicoloDettaglioManutenzioneColumnDefs(): ConfigColumnDefs[] {
    return [
        {
            target: 0,
            render: (id: number, type: string, row: IMagazzinoVeicoloManutenzione) => {
                const edit = `<a class="edit_btn custom-icon btn btn-outline-primary rounded-circle" data-id="${id}">
                    <i style="font-size: 18px" class="fa fa-pencil" aria-hidden="true"></i>
                </a>`;

                return `<div class="d-flex">${edit}</div>`;
            },
        },
    ];
}
