import axios, { AxiosResponse } from "axios"
import { IVeicoloForm } from "../../../config/formFields/magazzino/magazzino-veicoli";
import { IMagazzinoVeicolo } from "../../../helpers/interfaces/magazzino/magazzino-veicoli";

const API_URL = process.env.REACT_APP_API_URL;

class MagazzinoVeicoliService {

    async getAll(): Promise<IMagazzinoVeicolo[]> {
        try {
            const response = await axios.get<IMagazzinoVeicolo[]>(API_URL + "datore/vehicle/list");
            return Array.isArray(response.data) ? response.data : [];
        } catch (error) {
            return await Promise.reject();
        }
    }

    async getOne(id: string): Promise<IMagazzinoVeicolo> {
        try {
            const response = await axios.get<IMagazzinoVeicolo>(API_URL + 'datore/vehicle/get/' + id);
            return response.data;
        } catch (error) {
            return await Promise.reject();
        }
    }

    async add(body: IVeicoloForm): Promise<IMagazzinoVeicolo> {
        try {
            const response = await axios.post<IVeicoloForm, AxiosResponse<IMagazzinoVeicolo>>(API_URL + 'datore/vehicle/add', body);
            return response.data;
        } catch (error) {
            return await Promise.reject();
        }
    }

    async edit(id: string, body: IVeicoloForm): Promise<IMagazzinoVeicolo> {
        try {
            const response = await axios.put<IVeicoloForm, AxiosResponse<IMagazzinoVeicolo>>(API_URL + 'datore/vehicle/update/' + id, body);
            return response.data;
        } catch (error) {
            return await Promise.reject();
        }
    }

    async delete(id:any) {
        return axios.delete(API_URL + 'datore/vehicle/' + id)
        .then(response => { return response.data; })
        .catch((error) => { return error?.response.data; });
      }

}

export default new MagazzinoVeicoliService()