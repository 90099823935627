import * as Yup from 'yup'
import { iFormFieldValue, iFormField } from '../../../helpers/interfaces/generic';
import { IMagazzinoContrattoNoleggio, isMagazzinoContrattoNoleggio } from '../../../helpers/interfaces/magazzino/magazzino-contratti-noleggi';
import { IMagazzinoStatoStrumento } from '../../../helpers/interfaces/magazzino/magazzino-stato-strumenti';
import { IMagazzinoTipologiaStrumento } from '../../../helpers/interfaces/magazzino/magazzino-tipologia-strumenti';

export interface IStrumentoForm {
    acceptability?: string;
    builder?: string;
    calibrationdate?: string;
    categoria_id?: number;
    categoria_nome?: string;
    code?: string;
    expiration?: string;
    frequency?: string;
    id?: number;
    internalid?: string;
    location?: string;
    marca_modello?: string;
    misuramentrange?: string;
    purchase?: string;
    serial_number?: string;
    stato_id?: number;
    stato_nome?: string;
    tipologia_id?: number;
    tipologia_nome?: string;
    verify?: string;
    warranty?: string;
    noleggio: "Sì" | "No";
    contrattonoleggio: string;
}

const genericOptions: iFormFieldValue[] = [
    {
        key: 0,
        value: "No"
    },
    {
        key: 1,
        value: "Sì"
    }
]

const requiredMessage = 'Campo obbligatorio'

// function: mapping a generic list to return a new list for the select menus
export function mapListsData(list: IMagazzinoTipologiaStrumento[] | IMagazzinoStatoStrumento[] | IMagazzinoContrattoNoleggio[]): { key: number, value: string }[] {
    list.sort((a: any, b: any) => {
        return a.nome.localeCompare(b.nome);
    });
    return list.map(item => { return { key: item.id, value: item.nome + (isMagazzinoContrattoNoleggio(item) ? ' - ' + item.codice : '') } })
}

// function: setting form fields for devices' add/edit page
export function strumentiFormFields(
    allStates: iFormFieldValue[],
    allTypes: iFormFieldValue[],
    allCategories: iFormFieldValue[],
    allContracts: iFormFieldValue[]
): iFormField[] {
    return [
        {
            label: 'Stato',
            name: 'stato_nome',
            type: 'select',
            class: 'form-select',
            values: allStates,
            validation: Yup.string().required(requiredMessage)
        },
        {
            label: 'Tipologia',
            name: 'tipologia_nome',
            type: 'select',
            class: 'form-select',
            values: allTypes,
            validation: Yup.string().required(requiredMessage)
        },
        {
            label: 'Categoria',
            name: 'categoria_nome',
            type: 'select',
            class: 'form-select',
            values: allCategories,
            validation: Yup.string().required(requiredMessage)
        },
        {
            label: 'Internal Id',
            name: 'internalid',
            type: 'text',
            class: 'form-control',
            // validation: Yup.string().required(requiredMessage)
        },
        {
            label: 'Code',
            name: 'code',
            type: 'text',
            class: 'form-control',
            // validation: Yup.string().required(requiredMessage)
        },
        {
            label: 'Marca modello',
            name: 'marca_modello',
            type: 'text',
            class: 'form-control',
            validation: Yup.string().required(requiredMessage)
        },
        {
            label: 'Serial number',
            name: 'serial_number',
            type: 'text',
            class: 'form-control',
            validation: Yup.string().required(requiredMessage)
        },
        {
            label: 'Builder',
            name: 'builder',
            type: 'text',
            class: 'form-control',
            // validation: Yup.string().required(requiredMessage)
        },
        {
            label: 'Misurament range',
            name: 'misuramentrange',
            type: 'text',
            class: 'form-control',
            validation: Yup.string().required(requiredMessage)
        },
        {
            label: 'Calibration date',
            name: 'calibrationdate',
            type: 'text',
            class: 'form-control',
            // validation: Yup.string().required(requiredMessage)
        },
        {
            label: 'Frequency',
            name: 'frequency',
            type: 'text',
            class: 'form-control',
            validation: Yup.string().required(requiredMessage)
        },
        {
            label: 'Expiration',
            name: 'expiration',
            type: 'text',
            class: 'form-control',
            // validation: Yup.string().required(requiredMessage)
        },
        {
            label: 'Location',
            name: 'location',
            type: 'text',
            class: 'form-control',
            validation: Yup.string().required(requiredMessage)
        },
        {
            label: 'Purchase',
            name: 'purchase',
            type: 'text',
            class: 'form-control',
            // validation: Yup.string().required(requiredMessage)
        },
        {
            label: 'Warranty',
            name: 'warranty',
            type: 'text',
            class: 'form-control',
            // validation: Yup.string().required(requiredMessage)
        },
        {
            label: 'Verify',
            name: 'verify',
            type: 'text',
            class: 'form-control',
            // validation: Yup.string().required(requiredMessage)
        },
        {
            label: 'Acceptability',
            name: 'acceptability',
            type: 'text',
            class: 'form-control',
            // validation: Yup.string().required(requiredMessage)
        },
        {
            label: 'Noleggio',
            name: 'noleggio',
            type: 'select',
            values: genericOptions,
            class: 'form-select',
        },
        {
            label: 'Contratto noleggio',
            name: 'contrattonoleggio',
            type: 'select',
            values: allContracts,
            class: 'form-select',
        },
        {
            label: 'File allegati',
            name: 'attachments',
            type: 'file',
            class: 'form-control',
        }
    ];
    /*
    return [
        {
            label: 'Stato',
            name: 'stato_nome',
            type: 'select',
            values: allStates,
            class: 'form-select'
        },
        {
            label: 'Tipologia',
            name: 'tipologia_nome',
            type: 'select',
            values: allTypes,
            class: 'form-select',
            // validation: Yup.string().required(requiredMessage)
        },
        {
            label: 'Marca/Modello',
            name: 'marca_modello',
            type: 'text',
            class: 'form-control',
            // validation: Yup.string().required(requiredMessage)
        },
        {
            label: 'Serial number',
            name: 'serial_number',
            type: 'text',
            class: 'form-control',
            // validation: Yup.string().required(requiredMessage)
        },
        {
            label: 'Noleggio',
            name: 'noleggio',
            type: 'select',
            values: genericOptions,
            class: 'form-select',
        },
        {
            label: 'Contratto noleggio',
            name: 'contrattonoleggio',
            type: 'select',
            values: allContracts,
            class: 'form-select',
        },
        {
            label: 'In magazzino',
            name: 'inMagazzino',
            type: 'select',
            values: genericOptions,
            class: 'form-select',
        },
        {
            label: 'Cespiti',
            name: 'cespiti',
            type: 'select',
            values: genericOptions,
            class: 'form-select',
        },
        {
            label: 'Data acquisto',
            name: 'data_acquisto',
            type: 'date',
            class: 'form-control',
        },
        {
            label: 'Spec. tecniche',
            name: 'spec_tecniche',
            type: 'text',
            class: 'form-control',
        },
        {
            label: 'Note',
            name: 'note',
            type: 'text',
            class: 'form-control',
        },
        {
            label: 'File allegati',
            name: 'attachments',
            type: 'file',
            class: 'form-control',
        }
    ]; */
}