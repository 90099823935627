import axios from '../api';
import { IJobOrderExpiring } from '../../helpers/interfaces/home-amministrazione';
import { /*JoborderFormData,*/ JoborderRequest, JoborderResponse } from '../../helpers/interfaces/joborders';
import { AxiosResponse } from 'axios';
import eventBus from '../../common/EventBus';

const API_URL = process.env.REACT_APP_API_URL;

class CommesseService {
  async getAll(): Promise<JoborderResponse> {
    try {
      const response: AxiosResponse<JoborderResponse> = await axios.get(
        API_URL + 'v2/datore/joborders'
      );
      return response.data;
    } catch (error) {
      return await Promise.reject();
    }
  }

  async getAllExpiring(): Promise<IJobOrderExpiring> {
    try {
      const response = await axios.get<IJobOrderExpiring>(API_URL + 'datore/joborders/expiring');
      return response.data;
    } catch (error) {
      return await Promise.reject();
    }
  }

  async fromCustomer(id: number) {
    try {
      const response = await axios.get(API_URL + 'datore/joborders/customer/' + id);
      return response.data;
    } catch (error) {
      return [];
    }
  }

  async all(): Promise<JoborderResponse> {
    try {
      const response: AxiosResponse<JoborderResponse> = await axios.get<JoborderResponse>(
        API_URL + 'datore/joborders'
      );

      return response.data;
    } catch (error) {
      return await Promise.reject();
    }
  }

  async allForPlanning(): Promise<JoborderResponse> {
    try {
      const response: AxiosResponse<JoborderResponse> = await axios.get<JoborderResponse>(
        API_URL + 'datore/joborders/planning'
      );

      return response.data;
    } catch (error) {
      return await Promise.reject();
    }
  }

  async get(id: any) {
    try {
      const response = await axios.get(API_URL + 'datore/joborder/' + id);
      return typeof response.data == 'object' ? response.data : null;
    } catch (error) {
      return await Promise.reject();
    };
  }

  async put(id: any, data: any): Promise<any> {
    try {
      const response = await axios.put(API_URL + 'datore/joborder/' + id, {
        ...data
      });
      return response.data;
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  async put1stLevel(id: any, data: any): Promise<any> {
    const contattiId: Array<number> = [];

    data?.contatti.map(
      (contatto: any) => contattiId.push(contatto.id)
    );

    let formData: any = { 
      name: data.name,
      code: data.code,
      start: data.start,
      expire: data.expire,
      customerid: data.customerid,
      order: data.order,
      days: data.days,
      rateIsHourly: data.rateIsHourly,
      rate: data.rate,
      parentJoborder: "",
      contatti: contattiId,
      rittype: data.rittype,
      finished: data.finished
    };

    let payload: {[key: string]: any} = {}
    Object.keys(formData).forEach((key: any) => {
      if (formData[key] !== undefined) {
        payload[key] = formData[key]
      }
    });

    try {
      const response = await axios.put(API_URL + 'datore/joborder/' + id, payload);

      return response.data;
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  async put2ndLevel(id: any, data: JoborderRequest): Promise<any> {
    // const payload = { activity: data?.activity };
    const payload = { activity: Number(data?.activity) };
    
    try {
      const response = await axios.put(API_URL + 'datore/joborder/' + id, payload);
      return response.data;
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  async add(
    data: any
  ) {
    let payload: {[key: string]: any} = {}
    Object.keys(data).forEach((key: any) => {
      if (
        data[key] !== undefined &&
        key !== 'jobOrder_rit_type' &&
        key !== 'activity_rit_type'
      ) {
        payload[key] = data[key]
      }
    });

    if (data.joborderid && data.jobOrder_rit_type) {
      payload.rittype = data.jobOrder_rit_type.toString();
    }

    if (data.activity && data.activity_rit_type) {
      payload.rittype = data.activity_rit_type.toString();
    }

    try {
      const response = await axios.post(API_URL + 'datore/joborder', {
        ...payload
      });

      return response.data;
    } catch (error) {
      return Promise.reject();
    }
  }

  async delete(id: any) {
    try {
      const response = await axios.delete(API_URL + 'datore/joborder/' + id);
      return response.data;
    } catch (error: any) {
      return error.response.data;
    }
  }

  async stop(id: string | number) {
    try {
      const response = await axios.put(API_URL + 'datore/joborder/stop/' + id);
      return response.data;
    } catch (error) {
      return [];
    }
  }

  async renew(id: any, data: any) {
    let payload: {[key: string]: any} = {}
    Object.keys(data).forEach((key: any) => {
      if (
        data[key] !== undefined &&
        key !== 'jobOrder_rit_type' &&
        key !== 'activity_rit_type'
      ) {
        payload[key] = data[key]
      }
    });

    if (data.joborderid && data.jobOrder_rit_type) {
      payload.rittype = data.jobOrder_rit_type.toString();
    }

    if (data.activity && data.activity_rit_type) {
      payload.rittype = data.activity_rit_type.toString();
    }

    try {
      const response = await axios.post(API_URL + 'datore/joborder/renew', {
        ...data
      });
      return response.data;
    } catch (error) {
      return [];
    }
  }

  async personAdd(data: any) {
    try {
      const response = await axios.post(API_URL + 'datore/personjoborder', {
        ...data
      });

      if (
        response &&
        typeof response.data.error !== 'undefined'
      ) {
        eventBus.dispatch("hideLoader");

        return Promise.reject(response.data.error)
      }

      return response.data;
    } catch (error) {
      return await Promise.reject();
    }
  }

  async personAddList(body: any) {
    try {
      const response = await axios.post(API_URL + 'datore/personjoborder/addlist', body);
      return response.data;
    } catch (error) {
      return [];
    }
  }

  async personDelete(id: any) {
    try {
      const response = await axios.delete(API_URL + 'datore/personjoborder/' + id);
      return response.data;
    } catch (error: any) {
      return error.response.data;
    }
  }
  async personGet(id: any) {
    try {
      const response = await axios.get(API_URL + 'datore/personjoborder/' + id);
      return typeof response.data == 'object' ? response.data : null;
    } catch (error) {
      return await Promise.reject();
    };
  }
  async personPut(id: any, data: any) {
    try {
      const response = await axios.put(API_URL + 'datore/personjoborder/' + id, {
        ...data
      });
      return response.data;
    } catch (error) {
      return [];
    }
  }
}
export default new CommesseService();