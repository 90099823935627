import React, { Component } from "react";
import Swal from "sweetalert2";
import EventBus from "../../common/EventBus";
import ModalForm from '../../common/ModalForm';

import CausaliService from "../../services/api/database/causali.service";
import { listCausaliColumns, getListCausaliColumnDefs } from "../../config/tableColumns/organico/causali";
import { getEditCausaliFormFields, getAddCausaliFormFields } from "../../config/formFields/causali";
import Table from '../../common/TableComponent';
import { iFormField } from "../../helpers/interfaces/generic";

const $ = require('jquery');

type Props = {
    history: any
};
type State = {
    formFields: Array<iFormField>,
    loading: boolean,
    message: string,
    showModal: boolean,
    modalType: string,
    causali: any,
    formInitialValues: { [key: string]: any }
}
export default class Causali extends Component<Props, State> {
    innerRefs: any;
    apiSubmit: any;
    buttons: string[] | null;

    constructor(props: Props) {
        super(props);
        this.state = {
            formFields: [],
            causali: [],
            loading: false,
            message: "",
            showModal: false,
            modalType: 'add',
            formInitialValues: {},
        }
        this.innerRefs = [];
        this.apiSubmit = null;// LicenzeService.personAdd;
        this.buttons = null;
    }

    async componentDidMount() {
        EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });
        const causali = await CausaliService.getAll();
        if (causali.length > 0) {
            this.setState({ causali });
        }
        EventBus.dispatch("hideLoader");

        $('#table_causali.table').on('click', '.edit_btn', async (e: any) => {
            e.preventDefault();
            EventBus.dispatch("showLoader", { text: 'Caricamento Causali in corso...' });
            const idCausale = $(e.currentTarget).data('id');
            const causale: any = await CausaliService.get(idCausale);
            if (causale) {
                this.setState({ formFields: getEditCausaliFormFields(), formInitialValues: { ...causale, identificationcode: causale.code } }, () => {
                    this.apiSubmit = CausaliService.put;
                    this.setState({ showModal: true, modalType: 'edit' });
                });
            }
            EventBus.dispatch("hideLoader");
        });

        $('#table_causali.table').on('click', '.delete_btn', async (e: any) => {
            e.preventDefault();
            Swal.fire({
                title: 'Vuoi eliminare la causale sbagliata?',
                text: "ATTENZIONE: la cancellazione non è reversibile!! Eliminare solo causali create per sbaglio e non rendicontate!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Conferma',
                cancelButtonText: 'Annulla'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const idCausale = $(e.currentTarget).data('id');
                    const response: any = await CausaliService.delete(idCausale);
                    if (response && typeof response.error !== 'undefined') {
                        Swal.fire(
                            response.error,
                            '',
                            'error'
                        );
                    } else {
                        window.location.reload();
                    }
                }
            });
        });
    }

    closeModal() {
        this.setState({ showModal: false });
    }

    async openAddModale() {
        this.apiSubmit = CausaliService.add;
        this.setState({ formFields: getAddCausaliFormFields(), formInitialValues: {}, showModal: true, modalType: 'add' });
    }

    render() {
        const { formFields, showModal, modalType, causali, formInitialValues } = this.state;
        return <React.Fragment>
            <div className="custom-container">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center">
                            <h3>Causali</h3>
                            <button id="add_btn" type="button" className="btn btn-outline-primary" onClick={() => this.openAddModale()}>
                                <span>Aggiungi causale</span>
                            </button>
                        </div>
                        <ModalForm showModal={showModal} title={'Causale'} modalType={modalType} formFields={formFields} initialValues={formInitialValues} apiSubmit={this.apiSubmit} closeCallback={this.closeModal.bind(this)} />
                        {causali.length > 0 && <Table id="table_causali" columns={listCausaliColumns} columnDefs={getListCausaliColumnDefs()} datas={causali} buttons={this.buttons} />}
                    </div>
                </div>
            </div>
        </React.Fragment>
    }
}