/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, createRef } from 'react'
import { IPlanningEventData, IPlanningResource } from '../../helpers/interfaces/planning';
// import { IPlanningAddOrEditEvent } from '../../helpers/interfaces/planning';
import TableBootstrap, { TableBSColumn } from '../../common/TableBootstrap';
import { planningPerDateUtenteFormFields } from '../../config/formFields/planning';
import { getMesi } from '../../helpers/calendar';
import { IPlanningMonthType } from './planning';
import SearchBar from '../../common/SearchBar'
import $ from 'jquery';
import moment from "moment";
import 'moment/locale/it';
import './planning.css';
import { Formik, Form, FormikProps, Field } from "formik";
import { iFormField } from '../../helpers/interfaces/generic';
import * as Yup from 'yup';
import { RefObject } from '@fullcalendar/core';
import { Button } from 'react-bootstrap';
import groupsService from '../../services/api/database/groups.service';
import { GroupAll } from '../../helpers/interfaces/group';

type Props<T = any> = {
    year: number,
    month: number,
    setYearMonth: (year: number, month: number) => Promise<void>,
    getMonth: (year: number, month: number, types: IPlanningMonthType[]) => Promise<IPlanningMonth<T>>;
    getInterval?: (start: string, end: string, types: IPlanningMonthType[]) => Promise<IPlanningMonth<T>>;
    viewHandler: (id: number) => Promise<void>;
    addHandler?: () => Promise<void>;
    formResponse?: any;
    resourceType?: string;
    eventToDeleteId?: number;
    type: 'planning' | 'dipendente';
};

type State = {
    groups: GroupAll[],
    planningData: IPlanningEventData[] | null,
    planningColumns: TableBSColumn<IPlanningEventData>[][],
    startDateError: string | null,
    intervalError: string | null,
    loading: boolean,
    filters: {
        year: number,
        month: number,
        startDate: string,
        endDate: string,
        currentTypes: { index: number, label: IPlanningMonthType, isSelected: boolean }[]
        inputText: string,
        currentGroups: { id: number | undefined, name: string, isSelected: boolean }[]
    },
}

export type IPlanningMonth<T = any> = {
    planningData: T[] | null,
    planningColumns: TableBSColumn<T>[][]
}

export default class PlanningMonth extends Component<Props, State> {
    isAdministrator: boolean = true;
    clearBox: boolean = true;
    date = new Date();
    startCurrentDate = moment(this.date).format('YYYY-MM-DD')
    endDateOfNextMonth = moment(this.date).add(1, 'M').format('YYYY-MM-DD');
    datesFormInitialValues = {
        startdate: this.startCurrentDate,
        enddate: this.endDateOfNextMonth
    }
    planningByMonth: boolean = true;
    dateMaskFormFields = planningPerDateUtenteFormFields();
    innerRefs: { [key: string]: string };
    scrollableContentRef: React.RefObject<any>;
    formRef: RefObject<HTMLFormElement> = createRef();

    types: { index: number, label: IPlanningMonthType, isSelected: boolean }[] = [
        { index: 0, label: "people", isSelected: true },
        { index: 1, label: "instruments", isSelected: false },
        { index: 2, label: "vehicles", isSelected: false },
        { index: 3, label: "licenses", isSelected: false }
    ]

    constructor(props: Props) {
        super(props);

        this.state = {
            groups: [],
            planningData: null,
            planningColumns: [],
            startDateError: null,
            intervalError: null,
            loading: false,
            filters: {
                year: this.date.getFullYear(),
                month: this.date.getMonth(),
                startDate: this.startCurrentDate,
                endDate: this.endDateOfNextMonth,
                currentTypes: this.types,
                inputText: "",
                currentGroups: [{ id: undefined, name: "Tutti", isSelected: true }],
            },
        }

        this.isAdministrator = this.props.type === 'planning';
        this.innerRefs = {};
        this.setValidations = this.setValidations.bind(this)
        this.scrollableContentRef = React.createRef();
    }

    async componentDidMount() {
        const { filters } = this.state

        if (this.isAdministrator) {
            await groupsService.getAllScadenziario().then(
                groups => this.setState({
                    groups,
                    filters: {
                        ...filters, currentGroups: [
                            ...filters.currentGroups,
                            ...groups.map(group => {
                                return {
                                    id: group.id,
                                    name: group.nome,
                                    isSelected: false
                                }
                            })
                        ]
                    }
                })
            );

            await this.getInterval(this.state.filters.startDate, this.state.filters.endDate, this.state.filters.currentGroups);
        } else
            await this.getMonth(filters.year, filters.month);

        this.updatePosition = this.updatePosition.bind(this);
        document.body.addEventListener('scroll', this.updatePosition);

        $('#planning_month').on('click', '.view_btn', async (e: JQuery.ClickEvent) => {
            e.preventDefault();
            const id = $(e.currentTarget).data('id');
            await this.props.viewHandler(id);
        });

        $('#planning-month-people-wrapper .wrapper').width($("#table-planning-month-people").width() ?? 0);
    }

    componentWillUnmount() {
        document.body.removeEventListener('scroll', this.updatePosition);
    }

    componentDidUpdate(_prevProps: Readonly<Props<any>>, _prevState: Readonly<State>): void {
        const { filters } = this.state
        const currentLabels = filters.currentTypes.filter(type => type.isSelected).map(type => type.label).join("")
        $(`#planning-month-${currentLabels}-wrapper .wrapper`).width($(`#table-planning-month-${currentLabels}`).width() ?? 0);

        if ((_prevProps.formResponse !== this.props.formResponse && this.props.formResponse)) {
            let { planningData } = this.state;
            const { formResponse, resourceType } = this.props;

            let isNew: boolean = true;
            let elemIndex: number = -1;
            let resType: string = '';

            if (planningData && planningData.length > 0) { // [resource{}, plannings: []]
                planningData.map((elem: IPlanningEventData, index: number) => {
                    // per l'add
                    switch (resourceType) {
                        case 'people':
                            resType = 'P';
                            formResponse.persons.map((person: any) => {
                                if (person?.id === elem.resource?.id) {
                                    elemIndex = index;
                                };
                                return null;
                            });
                            break;
                        case 'instruments':
                            resType = 'I';
                            formResponse.devices.map((device: any) => {
                                if (device?.id === elem.resource?.id) {
                                    elemIndex = index;
                                };
                                return null;
                            });
                            break;
                        case 'vehicles':
                            resType = 'V';
                            formResponse.vehicles.map((vehicle: any) => {
                                if (vehicle?.id === elem.resource?.id) {
                                    elemIndex = index;
                                };
                                return null;
                            });
                            break;
                        case 'licenses':
                            resType = 'L';
                            formResponse.licenses.map((license: any) => {
                                if (license?.id === elem.resource?.id) {
                                    elemIndex = index;
                                };
                                return null;
                            });
                            break;
                    }

                    // per l'edit
                    elem.plannings.map((event: any, eventIndex) => {
                        if (event?.id === formResponse?.id) {
                            const _type = event.type; // qui non usare resType, viene aggiornato solo dall'operazione di add
                            if (planningData && planningData[index]) {
                                planningData[index].plannings[eventIndex] = formResponse;
                                planningData[index].plannings[eventIndex].type = _type;
                            }
                            isNew = false;
                        }
                        return null;
                    });

                    return null;
                });

                // per l'add
                if (isNew && elemIndex > -1) {
                    formResponse.type = resType;
                    planningData[elemIndex].plannings.push(formResponse)
                }
    
                this.setState({ planningData: [...planningData] });
            }
        }

        if ((_prevProps.eventToDeleteId !== this.props.eventToDeleteId && this.props.eventToDeleteId)) {
            let { planningData } = this.state;
            const { eventToDeleteId } = this.props;

            if (planningData && planningData.length > 0) { // [resource{}, plannings: []]
                planningData.map((elem: IPlanningEventData, index: number) => {
                    elem.plannings.map((event: any, eventIndex) => {
                        if (Number(event?.id) === Number(eventToDeleteId)) {
                            if (planningData && planningData[index]) {
                                planningData[index].plannings.splice(eventIndex, 1);
                            }
                        };
                        return null;
                    });
                    return null;
                });

                this.setState({ planningData: [...planningData] });
            }
        }
    }

    updatePosition() {
        const scrollableContent = document.querySelector('#planning-container .tab-content');
        const targetPosition = 2; // Posizione di scroll desiderata

        if (scrollableContent) {
            const rect = scrollableContent.getBoundingClientRect();
            // const scrollY = window.scrollY || window.pageYOffset;
            const scrollY = document.body.scrollTop;

            if (rect.top <= targetPosition) {
                document.body.scrollTo({
                    top: scrollY + rect.top
                });
            }
        }
    };

    sortArray(x: any, y: any) {
        return x.fullname.localeCompare(y.fullname);
    }

    setPlanningData(monthData: IPlanningMonth<any>, groups?: { id: number | undefined, name: string, isSelected: boolean }[]) {
        if (this.props.type === 'planning') {
            if (monthData && monthData.planningData && monthData.planningData.length > 0) {
                let resources: IPlanningResource[] = [];
                if (groups && groups.length > 0) {
                    const selectedGroups = groups.filter(group => group.isSelected);
                    selectedGroups.forEach(group => {
                        monthData.planningData?.filter(data => data.resource.type === "people")?.forEach((item: IPlanningEventData) => {
                            if (item.resource.type === "people" && ((item.resource.group && item.resource.group?.id === group.id) || group.id === undefined))
                                resources.push(item.resource)
                        });
                    })
                    monthData.planningData?.filter(data => data.resource.type !== "people").forEach((item: IPlanningEventData) => {
                        resources.push(item.resource)
                    })
                } else
                    monthData.planningData.map((item: any) => resources.push(item.resource));

                resources = resources.sort(this.sortArray);

                let newPlanningData: IPlanningEventData[] = [];
                const people = monthData.planningData?.filter(item => item.resource.type === "people")
                const instruments = monthData.planningData?.filter(item => item.resource.type === "instruments")
                const vehicles = monthData.planningData?.filter(item => item.resource.type === "vehicles")
                const licenses = monthData.planningData?.filter(item => item.resource.type === "licenses")

                resources.forEach((data: IPlanningResource) => {
                    if (people && people.length > 0 && data.type === "people") {
                        const findPeople = people.filter(item => item.resource.id === data.id)
                        if (findPeople)
                            newPlanningData = newPlanningData.concat(findPeople)
                    }
                    if (instruments && instruments.length > 0 && data.type === "instruments") {
                        const findInstruments = instruments.filter(item => item.resource.id === data.id)
                        if (findInstruments)
                            newPlanningData = newPlanningData.concat(findInstruments)
                    }
                    if (vehicles && vehicles.length > 0 && data.type === "vehicles") {
                        const findVehicles = vehicles.filter(item => item.resource.id === data.id)
                        if (findVehicles)
                            newPlanningData = newPlanningData.concat(findVehicles)
                    }
                    if (licenses && licenses.length > 0 && data.type === "licenses") {
                        const findLicenses = licenses.filter(item => item.resource.id === data.id)
                        if (findLicenses)
                            newPlanningData = newPlanningData.concat(findLicenses)
                    }
                })

                monthData.planningData = newPlanningData
            }
        }
    }

    async getMonth(year: number, month: number, groups?: { id: number | undefined, name: string, isSelected: boolean }[]) {
        const { filters } = this.state
        this.planningByMonth = true;

        const selectedTypes = filters.currentTypes.filter(type => type.isSelected)
        const monthData = await this.props.getMonth(year, month, selectedTypes.map(type => type.label));

        const newDate = moment().day(1).month(month).year(year)
        const startDate = moment(newDate).startOf('month').format('YYYY-MM-DD')
        const endDate = moment(newDate).endOf('month').format('YYYY-MM-DD');

        this.setPlanningData(monthData, groups)

        this.setState({
            planningColumns: monthData.planningColumns,
            planningData: monthData.planningData ?? [],
            filters: {
                ...this.state.filters,
                year,
                month,
                startDate,
                endDate
            }
        })
    }

    async getInterval(start: string, end: string, groups: { id: number | undefined, name: string, isSelected: boolean }[]) {
        const { filters } = this.state;
        this.planningByMonth = false;

        if (this.props.getInterval) {
            const intervalData = await this.props.getInterval(start, end, filters.currentTypes.filter(type => type.isSelected).map(type => type.label));

            this.setPlanningData(intervalData, groups);

            const _year = new Date(start).getFullYear();
            const _month = new Date(start).getMonth();

            this.setState({
                planningColumns: intervalData.planningColumns,
                planningData: intervalData.planningData ?? [],
                filters: {
                    ...filters,
                    year: _year,
                    month: _month
                }
            })
        }
    }

    setInputText(inputText: string) {
        this.setState({ filters: { ...this.state.filters, inputText } });
        this.clearBox = false;
    }

    onIntervalChange(dates: { startDate: string, endDate: string }) {
        const s = new Date(dates.startDate);
        const e = new Date(dates.endDate);

        if (s instanceof Date && !isNaN(s.getTime()) && e instanceof Date && !isNaN(e.getTime())) {
            const start = s.setDate(s.getDate());
            const max = s.setDate(s.getDate() + 90);
            const end = e.setDate(e.getDate());

            if (start > end)
                this.setState({ startDateError: "La data di fine intervallo deve essere maggiore di quella di inizio." })
            else if (end <= max) {
                this.getInterval(dates.startDate, dates.endDate, this.state.filters.currentGroups);
                this.setState({
                    intervalError: null,
                    startDateError: null,
                    filters: {
                        ...this.state.filters,
                        startDate: dates.startDate,
                        endDate: dates.endDate
                    },
                })
            } else
                this.setState({ intervalError: "Il massimo intervallo selezionabile è di 90 giorni." })
        }
    }

    setValidations() {
        let validations: any = {};
        this.dateMaskFormFields.forEach(value => (validations[value.name] = value.validation));
        return Yup.object().shape(validations);
    }

    checkDates() {
        const { filters } = this.state
        const newDate = moment().day(1).month(filters.month).year(filters.year)
        const startDate = moment(newDate).startOf('month').format('YYYY-MM-DD')
        const endDate = moment(newDate).endOf('month').format('YYYY-MM-DD');
        if (filters.startDate !== startDate || filters.endDate !== endDate)
            this.getInterval(filters.startDate, filters.endDate, filters.currentGroups)
        else
            this.getMonth(filters.year, filters.month, filters.currentGroups)
    }

    setType(index: number, label: IPlanningMonthType) {
        const { filters } = this.state
        const newTypes = [...filters.currentTypes]
        const findOldType = newTypes.find(type => type.index === index)
        if (findOldType) {
            newTypes.splice(index, 1, { index, label, isSelected: !findOldType.isSelected })
            this.setState({ filters: { ...filters, currentTypes: newTypes } }, () => this.checkDates())
        }
    }

    setGroups(groupId: number | undefined) {
        const { filters } = this.state
        let newGroups = [...filters.currentGroups]
        const findOldGroup = newGroups.find(group => group.id === groupId)
        if (findOldGroup) {
            newGroups.splice(newGroups.indexOf(findOldGroup), 1, { id: groupId, name: findOldGroup.name, isSelected: !findOldGroup.isSelected })
            if (groupId === undefined) {
                newGroups = newGroups.map((group, index) => {
                    if (index !== 0)
                        return {
                            ...group,
                            isSelected: false
                        }
                    return group
                })
            } else {
                newGroups.splice(0, 1, { id: undefined, name: "Tutti", isSelected: false })
            }
            this.setState({ filters: { ...filters, currentGroups: newGroups } }, () => this.checkDates())
        }
    }

    render() {
        const { filters, planningColumns, planningData, groups, startDateError, intervalError } = this.state;
        const { inputText, year, month, currentGroups, startDate, endDate, currentTypes } = filters;

        const years = [];

        for (let i = moment().year() + 1; i >= 2016; i--) {
            years.push(i);
        }

        const currentLabels = currentTypes.filter(type => type.isSelected).map(type => type.label).join("")

        return <div id="planning-container">
            <div className="col-12 d-flex flex-column-reverse flex-md-row justify-content-md-between align-items-center mb-3">
                <div className="col-12 col-md-6 d-flex flex-column flex-md-row align-items-start align-items-md-center">
                    <p className="form-label mb-2 mb-md-0 me-0 me-md-3">Mese e anno</p>
                    <div className="col-12 col-md-8 col-lg-6 d-flex justify-content-center justify-content-lg-start pe-lg-2 align-items-center">
                        <div className="col-8 col-lg-6 col-lg-8 pe-2">
                            <select
                                className="form-select fw-bold"
                                value={month}
                                title="Seleziona mese"
                                onChange={(e) => {
                                    const value = e.target?.value
                                    if (value) {
                                        this.getMonth(year, Number(value))
                                    }
                                }}>
                                {
                                    getMesi().map(
                                        (mese: string, index: number) => {
                                            return <option key={index} value={index}>{mese}</option>
                                        }
                                    )
                                }
                            </select>
                        </div>
                        <div className="col-4 col-lg-6 col-lg-4">
                            <select
                                value={year}
                                className="form-select pe-0 fw-bold"
                                title="Seleziona anno"
                                onChange={(e) => {
                                    const value = Number(e.target?.value)
                                    if (value && value !== year)
                                        this.getMonth(value, month)
                                }}
                            >
                                {
                                    years.map((value: number, index: number) => {
                                        return <option key={index} value={value}>{value}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>
                </div>
                {
                    this.isAdministrator && this.props.addHandler && <div className="col-12 col-md-6 d-flex mb-2 mb-md-0 justify-content-end">
                        <a
                            id="add_btn"
                            href="#add"
                            className="btn btn-outline-primary"
                            onClick={async (e) => {
                                e.preventDefault();
                                if (this.props.addHandler) {
                                    await this.props.addHandler();
                                }
                            }}
                        >
                            <span>Aggiungi evento</span>
                        </a>
                    </div>
                }
            </div>
            {
                this.isAdministrator && <div className="col-12 my-2 my-lg-3">
                    <Formik
                        enableReinitialize
                        initialValues={{
                            startDate: startDate,
                            endDate: endDate
                        }}
                        validationSchema={this.setValidations.bind(this)}
                        onSubmit={() => { }}
                    >
                        {(formik: FormikProps<any>) => {
                            return <Form ref={this.formRef} onChange={(e: any) => {
                                const value = e.target.value
                                if (value) {
                                    this.setState(
                                        { filters: { ...filters, [e.target.name]: value } },
                                        () => this.onIntervalChange({
                                            startDate: e.target.name === "startDate" ? value : filters.startDate,
                                            endDate: e.target.name === "endDate" ? value : filters.endDate,
                                        })
                                    )
                                }
                            }} className="col-12 d-flex flex-column flex-md-row">
                                {
                                    this.dateMaskFormFields && this.dateMaskFormFields.map((item: iFormField, key: number) => {
                                        return <div className="col-12 col-md-6 d-flex flex-column flex-lg-row align-items-start align-items-lg-center mb-2 mb-lg-0" key={key}>
                                            <label className="form-label me-0 me-lg-3 mb-2 mb-lg-0">{item.label}</label>
                                            <div className={"col-12 col-lg-8 " + (key === 0 ? " pe-md-2 pe-0" : "")}>
                                                <Field
                                                    innerRef={(el: any) => this.innerRefs[item.name] = el}
                                                    name={item.name}
                                                    type={item.type}
                                                    className={item.class}
                                                    value={formik.values[item.name]}
                                                />
                                            </div>
                                        </div>
                                    })
                                }
                            </Form>
                        }}
                    </Formik>
                    {startDateError && <p className='my-1 text-danger fw-bold'>{startDateError}</p>}
                    {intervalError && <p className='my-1 text-danger fw-bold'>{intervalError}</p>}
                </div>
            }
            {
                this.isAdministrator && <div className="col-12 d-flex flex-column flex-md-row align-items-center my-2 my-lg-3">
                    <div className="col-12 col-md-6 d-flex flex-column flex-md-row align-items-start align-items-md-center flex-wrap">
                        <p className='form-label me-0 me-md-3 mb-2 mb-xl-0'>Filtra per tipo</p>
                        <div className="d-flex align-items-start align-items-md-center flex-wrap mb-2 mb-md-0">
                            <Button className='me-2 mb-2 mb-lg-0' variant={currentTypes[0].isSelected ? "primary" : "outline-primary"} onClick={() => this.setType(0, "people")}>
                                Dipendenti
                            </Button>
                            <Button className='me-2 mb-2 mb-lg-0' variant={currentTypes[1].isSelected ? "primary" : "outline-primary"} onClick={() => this.setType(1, "instruments")}>
                                Strumenti
                            </Button>
                            <Button className='me-2 mb-2 mb-lg-0' variant={currentTypes[2].isSelected ? "primary" : "outline-primary"} onClick={() => this.setType(2, "vehicles")}>
                                Veicoli
                            </Button>
                            <Button className='me-2 mb-2 mb-lg-0' variant={currentTypes[3].isSelected ? "primary" : "outline-primary"} onClick={() => this.setType(3, "licenses")}>
                                Licenze
                            </Button>
                        </div>
                    </div>
                    <div className='col-12 col-md-6 d-flex flex-column flex-lg-row align-items-start align-items-lg-center'>
                        <p className='form-label me-0 me-md-3 mb-2 mb-lg-0'>Ricerca</p>
                        <div className="col-12 col-lg-8">
                            <SearchBar
                                parentCallback={this.setInputText.bind(this)}
                                clearBox={this.clearBox}
                            />
                        </div>
                    </div>
                </div>
            }
            {
                this.isAdministrator && currentTypes[0].isSelected && groups.length > 0 && <div className="col-12 d-flex flex-column flex-xl-row align-items-start align-items-xl-center my-2 my-lg-3">
                    <p className='form-label mb-2 mb-xl-0 me-0 me-xl-3'>Filtra gruppo</p>
                    <div className="flex-wrap">
                        {
                            filters.currentGroups.map((group, index) => <Button key={group.id + "-" + index} className='me-2 my-1' variant={currentGroups[index].isSelected ? "secondary" : "outline-secondary"} onClick={() => this.setGroups(group.id)}>
                                {group.name}
                            </Button>)
                        }
                    </div>
                </div>
            }
            {
                this.isAdministrator && <div id="planning_month" className='position-relative'>
                    <div id="tabs-planning_month" className="mt-2 mt-md-3">
                        <div className='d-contents'>
                            <div id={"planning-month-" + currentLabels + "-wrapper"} className="wrapper-container" onScroll={() => {
                                $("#planning-month-" + currentLabels + "-container")
                                    .scrollLeft($("#planning-month-" + currentLabels + "-wrapper").scrollLeft() ?? 0);
                            }}>
                                <div className="wrapper"></div>
                            </div>
                            <div id={"planning-month-" + currentLabels + "-container"} className='scrollable-table' ref={this.scrollableContentRef} onScroll={() => {
                                $("#planning-month-" + currentLabels + "-wrapper")
                                    .scrollLeft($("#planning-month-" + currentLabels + "-container").scrollLeft() ?? 0);
                            }}>
                                <TableBootstrap
                                    id={"table-planning-month-" + currentLabels}
                                    className="table-planning-month"
                                    inputText={inputText}
                                    columnRows={planningColumns}
                                    data={planningData ?? []}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                this.props.type !== 'dipendente' ? <div id="planning_month" className='position-relative'>
                    <div className="d-flex d-md-none col-12 justify-content-center">
                        <div className="col-12">
                            <SearchBar
                                parentCallback={this.setInputText.bind(this)}
                                clearBox={this.clearBox}
                            />
                        </div>
                    </div>
                </div> : <TableBootstrap
                    id="planning_month"
                    columnRows={planningColumns}
                    data={planningData ?? []} />
            }

        </div>
    }
}
