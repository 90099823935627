import React, { Component } from "react";
import EventBus from "../../../common/EventBus";
import ModalForm from '../../../common/ModalForm';
import Pagination from '../../../common/Pagination';
import PermessiFerieService from "../../../services/api/permessiferie.service";
import { listPermessiFerieConfermateColumns, getListPermessiFerieConfermateColumnDefs } from "../../../config/tableColumns/permessi-ferie";
import { getRefusePermessiFerieFormFields } from "../../../config/formFields/permessiferie";
import Table from '../../../common/TableComponent';
import { iFormField } from "../../../helpers/interfaces/generic";
import Swal from "sweetalert2";

const $ = require('jquery');

type Props = {};

type State = {
    loading: boolean;
    formFields: Array<iFormField>;
    formInitialValues: { [key: string]: any };
    showModal: boolean;
    modalType: string;
    message: string;
    richieste: any;
    numeroRichieste: number;
    limit: number;
    offset: number;
}
export default class Confermate extends Component<Props, State> {

    apiSubmit: any;
    buttons: string[];
    defaultLimit: number = 10;
    defaultOffset: number = 0;

    constructor(props: Props) {
        super(props);

        this.state = {
            loading: false,
            formFields: [],
            formInitialValues: {},
            showModal: false,
            modalType: 'add',
            message: "",
            richieste: [],
            numeroRichieste: 0,
            limit: this.defaultLimit,
            offset: this.defaultOffset
        };

        this.apiSubmit = null;

        this.buttons = [
            //"copy",
            //"csv",
            "excel",
            "pdf",
            "print",
            //"colvis"
        ];
    }

    async componentDidMount() {
        EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });
        const results: any = await PermessiFerieService.getPermessiFerie(this.defaultLimit, this.defaultOffset, 2);
        if (results.row.length > 0) {
            this.setState({ richieste: results.row, numeroRichieste: results.total_row });
        }
        EventBus.dispatch("hideLoader");

        $('.table').on('click', '.delete_btn', async (e: any) => {
            e.preventDefault();
            const idRichiesta = $(e.currentTarget).data('id');
            this.apiSubmit = PermessiFerieService.refuse;
            this.setState({ formFields: getRefusePermessiFerieFormFields(), formInitialValues: { id: idRichiesta, note: '' }, showModal: true, modalType: 'edit' });
        });
    }

    closeModal() {
        this.setState({ showModal: false });
    }

    setItemsToRender = async (pages: any, page: any) => {
        EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });

        const limit = pages;
        const offset = (page - 1) * pages;

        PermessiFerieService.getPermessiFerie(limit, offset, 2)
        .then((response: any) => {
            this.setState({
                limit,
                offset,
                richieste: response.row,
                numeroRichieste: response.total_row
            });
        })
        .catch(() => {
            Swal.fire({
                title: 'Si è verificato un errore.',
                text: 'Non è stato possibile recuperare i dati.',
                icon: 'error',
            });
        })
        .finally(() => 
            EventBus.dispatch("hideLoader")
        );
    };

    render() {
        const { formFields, formInitialValues, showModal, modalType, richieste, numeroRichieste } = this.state;
        return <React.Fragment>
            <div className="row mt-3">
                <ModalForm showModal={showModal} title={'Rifiuta'} modalType={modalType} formFields={formFields} initialValues={formInitialValues} apiSubmit={this.apiSubmit} closeCallback={this.closeModal.bind(this)} />
                <h3 className="card-title">Confermate</h3>
                {richieste.length > 0 ? 
                    <>
                        <Table
                            id={"table_confermate"}
                            columns={listPermessiFerieConfermateColumns}
                            columnDefs={getListPermessiFerieConfermateColumnDefs()}
                            datas={richieste}
                            buttons={this.buttons}
                            searching={false}
                            paging={false}
                            info={false}
                        />
                        <Pagination itemsNumber={numeroRichieste} defaultItemsPerPage={this.defaultLimit} updateCallback={this.setItemsToRender.bind(this)} />
                    </>
                    : <p>Non sono presenti RICHIESTE</p>
                }
            </div>
        </React.Fragment>
    }
}