import * as Yup from "yup";
import { iFormField, iFormFieldValue } from "../../helpers/interfaces/generic";

export function getRapportiniGiornalieroFormFields(dipendenti: iFormFieldValue[]): Array<iFormField> {
  return [
    {
      'label': 'Giorno',
      'name': 'date',
      'type': 'date',
      'class': 'form-control',
      'validation': Yup.string().required("Campo obbligatorio")
    },
    // {
    //   'label': 'Dipendenti',
    //   'name': 'person_id',
    //   'type': 'select',
    //   'values': dipendenti,
    //   'class': 'form-select',
    // },
    // {
    //   'label': 'Genera unico foglio Excel',
    //   'name': 'perconsulente',
    //   'type': 'checkbox',
    //   'class': 'form-check-input'
    // },
  ]
}

export function getRapportiniMensileFormFields(mesi: iFormFieldValue[], dipendenti: iFormFieldValue[], updateUserField: (value: any) => void): Array<iFormField> {
  const emptyField = (dipendenti && dipendenti.length > 0) ? false : true;

  return [
    {
      'label': 'Mese',
      'name': 'date',
      'type': 'month',
      'class': 'form-control',
      'validation': Yup.string().required("Campo obbligatorio"),
      "updateField": "date",
      "updateCallback": updateUserField
    },
    // {
    //   'label': 'Mese',
    //   'name': 'date',
    //   'type': 'select',
    //   'values': mesi,
    //   'class': 'form-select',
    //   "updateField": "date",
    //   "updateCallback": updateUserField
    // },
    {
      'label': 'Dipendenti',
      'name': 'person_id',
      'type': 'select',
      'values': dipendenti,
      'value': emptyField ? 'Non ci sono dati' : 'Tutti',
      'class': 'form-select',
    },
    {
      'label': 'Genera unico foglio Excel',
      'name': 'perconsulente',
      'type': 'checkbox',
      'class': 'form-check-input'
    },
  ]
}

export function getRapportiniPresenzeFormFields(dipendenti: iFormFieldValue[]): Array<iFormField> {
  return [
    {
      'label': 'Mese',
      'name': 'date',
      'type': 'month',
      'class': 'form-control',
      'validation': Yup.string().required("Campo obbligatorio")
    },
    {
      'label': 'Dipendenti',
      'name': 'person_id',
      'type': 'select',
      'values': dipendenti,
      'class': 'form-select',
    },
    {
      'label': 'Genera unico foglio Excel',
      'name': 'perconsulente',
      'type': 'checkbox',
      'class': 'form-check-input'
    },
  ]
}

export function getRapportiniAnnualeFormFields(anni: any, dipendenti: any): Array<iFormField> {
  return [
    {
      'label': 'Anno',
      'name': 'year',
      'type': 'select',
      'value': '- Seleziona un anno -',
      'values': anni,
      'class': 'form-select',
      'validation': Yup.string().required("Campo obbligatorio")
    },
    {
      'label': 'Dipendenti',
      'name': 'personId',
      'type': 'select',
      'value': '- Seleziona un dipendente -',
      'values': dipendenti,
      'class': 'form-select',
      'validation': Yup.string().required("Campo obbligatorio")
    },
  ]
}

export function getRapportiniCommesseFormFields(
  anni: iFormFieldValue[],
  mesi: iFormFieldValue[],
  commesse: iFormFieldValue[],
  updateYearField: (value: string) => void,
  updateMonthField: (value: string) => void
): Array<iFormField> {
  
  const elencoAnni: any = [];
  for (let i=0; i <= anni.length-1; i++) {
    elencoAnni.push(anni[i].value);
  }

  return [
    {
      'label': 'Anno',
      'name': 'year',
      'type': 'select',
      'value': '- Seleziona un anno -',
      'values': anni,
      'class': 'form-select',
      'validation': Yup.string().required("Campo obbligatorio"),
      'updateField': 'year',
      'updateCallback': updateYearField
    },
    {
      'label': 'Mese',
      'name': 'month',
      'type': 'select',
      'values': mesi,
      'class': 'form-select',
      'updateField': 'month',
      'updateCallback': updateMonthField,
      // 'showFromFieldName': 'year',
      // 'showFromFieldValue': elencoAnni
    },
    {
      'label': 'Commessa',
      'name': 'joborder',
      'type': 'select',
      'values': commesse,
      'class': 'form-select'
    },
  ]
}

export function getRapportiniMaterialeFormFields(stati: any): Array<iFormField> {
  return [
    {
      'label': 'Stato dipendente',
      'name': 'stato',
      'type': 'select',
      'values': stati,
      'class': 'form-select',
      'validation': Yup.string()
    }
  ]
}

export function getRapportiniAnagraficaFormFields(stati: any): Array<iFormField> {
  return [
    {
      'label': 'Stato dipendente',
      'name': 'stato',
      'type': 'select',
      'values': stati,
      'class': 'form-select',
      'validation': Yup.string()
    }
  ]
}

export function getRapportiniPerDataFormFields(tipologia: iFormFieldValue[]): Array<iFormField> {
  return [
    {
      'label': 'Tipo di documento',
      'name': 'tipo',
      'type': 'select',
      'value': '- Seleziona la tipologia -',
      'values': tipologia,
      'class': 'form-select',
      'placeholder': 'Select music',
      'validation': Yup.string().required("Campo obbligatorio")
    },
    {
      'label': 'Data iniziale',
      'name': 'startdate',
      'type': 'date',
      'class': 'form-control',
      'validation': Yup.string().required("Campo obbligatorio")
    },
    {
      'label': 'Data finale',
      'name': 'enddate',
      'type': 'date',
      'class': 'form-control',
      'validation': Yup.string().required("Campo obbligatorio")
    }
  ]
}